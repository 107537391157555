import axios from 'axios';

/**
 * 
 * @param {String} query 
 */
export function queryRequest(query) {
  return axios.get(`/search/?query=${encodeURIComponent(query)}`);
}

/**
 * 
 * @param {String} parent 
 * @param {String} filename 
 * @param {Int} page 
 */
export function openPdfRequest(parent, filename, page) {
  let query = "";
  if (page >= 0) {
    query += `#page=${page}`;
  }
  if(parent.length > 0) {
    window.open(`${process.env.VUE_APP_SITE_API_URL}/load_doc/${encodeURIComponent(parent)}/${encodeURIComponent(filename)}${query}`, '_blank').focus();
  } else {
    window.open(`${process.env.VUE_APP_SITE_API_URL}/load_doc/${encodeURIComponent(filename)}${query}`, '_blank').focus();
  }
}


export function loadTreeRequest() {
  return axios.get(`${process.env.VUE_APP_SITE_API_URL}/load_tree/`);
}